import { type FC, useMemo, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useCustomer, useTranslator } from '@/hooks'
import type { TypeSearchResults } from '@dy/commons/schema'
import { Figure } from '@dy/commons/components'
import { NEXT_PUBLIC_LOCALE } from '@dy/commons/utils'
import { mq, vw, hex2Rgba, getP15 } from '@dy/commons/styles'
import { useRouter } from 'next/router'

const LANG = NEXT_PUBLIC_LOCALE.split('-')[0]

type Props = {
  data: TypeSearchResults,
  close: () => void
}

// This const resultIconByType keys set the order of the results by type
const resultIconByType = {
  search: () => ({
    type: 'svg' as const,
    src: '/images/svg/i--search-result-lupa.svg',
    alt: 'Search go page icon',
    size: { width: 40, height: 40 }
  }),
  categories: () => ({
    type: 'svg' as const,
    src: '/images/svg/i--search-result-category.svg',
    alt: 'Search result - category',
    size: { width: 16, height: 16 }
  }),
  brands: (item) => item?.logo,
  articles: () => ({
    type: 'svg' as const,
    src: '/images/svg/i--search-result-article.svg',
    alt: 'Search result - blog article',
    size: { width: 16, height: 16 }
  }),
  variants: (item) => item?.media?.[0]?.medium
}

const localStorageItem = 'mammafiore_search_history'
const getHistory = (raw = true) => {
  const historyVariants = JSON.parse(localStorage.getItem(localStorageItem)) || {}
  if (raw) return historyVariants

  // Adapt to the results data schema, to be able to reuse the getListItems function
  let historyVariantsArray = []
  for (let slug of Object.keys(historyVariants)) {
    historyVariantsArray.push(historyVariants[slug])
  }
  return { variants: historyVariantsArray }
}

const updateSearchHistory = (item) => {
  const currentHistory = getHistory()

  const slug = item?.product?.slug?.[LANG] ?? item?.slug?.[LANG]
  if(!slug) return
  if (!currentHistory) localStorage.setItem(localStorageItem, JSON.stringify({ [slug]: item }))
  if (!currentHistory[slug]) {
    const incomingHistory = { ...currentHistory, [slug]: item }
    localStorage.setItem(localStorageItem, JSON.stringify(incomingHistory))
  }
}

const updateQueryParam = (push, query) => {
  push({
    pathname: '/',
    search: query
  })
}

const pathname = {
  categories: '/',
  brand: '/brand/',
  variants: '/product/'
}

const getListItems = (customer, data, close, push) => {
  const items = []
  for (let x of Object.keys(resultIconByType)) {
    const arr = data[x]
    if (!Array.isArray(arr)) continue

    for (const [idx, item] of arr.entries()) {

      const slug = item?.product?.slug?.[LANG] ? item?.product?.slug?.[LANG] : item?.slug?.[LANG]
      const href = !slug ? item.href : (customer ? '/shop' : x === 'brand' ? '' : '/catalog') + pathname[x] + slug+`?v=${item.name}`
      const media = resultIconByType[x](item)
      items.push(<ListItem key={`${idx}-result ${item?.bigcommerceId}`} onClick={() => {
        if(x === 'variants') updateSearchHistory(item)
        else if(x === 'search') updateQueryParam(push, href)
        if(x !== 'search') close()
      }}>
        <a href={href && x !== 'brands' ? href : customer ? `/shop/brand/${item?.slug?.[LANG]}` : `/brand/${item?.slug?.[LANG]}`}>
          {x === 'brands' && item.logo?.url ?
            // eslint-disable-next-line @next/next/no-img-element
            <img src={item.logo?.url} alt={item.name} className={x} />
            :
            <Figure className={x} media={media}/>
          }
          <span>{item?.product?.name ?? item?.name} {`- ${item?.name}`}</span>
        </a>
      </ListItem>)
    }
  }
  return items
}

export const SearchResults: FC<Props> = ({ data = {}, close }) => {
  const { customer } = useCustomer()
  const { push } = useRouter()
  const ListItems = useMemo(() => getListItems(!!customer, data, close, push), [customer, data, close, push])
  const [historyItemsLength, setHistoryItemsLength] = useState(undefined)
  const HistoryItems = useMemo(() => (typeof historyItemsLength !== 'number' || historyItemsLength > 0) ? getListItems(!!customer, getHistory(false), close, push) : [], [historyItemsLength, customer, close, push])

  const { t } = useTranslator()

  const onClickClearHistory = () => {
    localStorage.removeItem(localStorageItem)
    setHistoryItemsLength(0)
  }

  return (
    <List>
      {ListItems?.length > 0 ?
        <>
          <dt>{t('layout.search.search_results')}</dt>
          {ListItems}
        </>
        :
        <>
          {HistoryItems?.length > 0 && <>
            <dt>{t('layout.search.history')}. <button onClick={onClickClearHistory}>{t('commons.actions.delete')}</button></dt>
            {HistoryItems}
          </>}
        </>
      }
    </List>
  )
}

const List = styled.dl`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  overflow: auto;
  padding: ${vw(20, 'mobile')} 0;
  margin-bottom: 0;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(20, 'nexus7')} 0;
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 2 / span 5;
    padding: ${vw(50, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 50px 0;
  }

  dt {
    color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .5)};
    font-weight: 400;
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }

    button {
      color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .5)};
      text-decoration: underline;
      transition: 300ms color ease-out;

      &:hover {
        color: ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
      }
    }
  }
`

const ListItem = styled(motion.dd)`
  color: ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
  font-weight: 500;
  margin-bottom: ${vw(10, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(10, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 10px;
  }

  a {
    ${getP15}
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: none;
    width: 100%;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      opacity: .8;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 300ms opacity ease-out;
      white-space: nowrap;
    }
  }

  figure, img {
    align-items: center;
    display: flex;
    height: ${vw(40, 'mobile')};
    justify-content: center;
    margin-right: ${vw(15, 'mobile')};
    width: ${vw(40, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(40, 'nexus7')};
      margin-right: ${vw(15, 'nexus7')};
      width: ${vw(40, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(40, 'desktop')};
      margin-right: ${vw(15, 'desktop')};
      width: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 40px;
      margin-right: 15px;
      width: 40px;
    }

    &.placeholder {
      background-color: rgba(0, 0, 0, .15);
    }
  }
`
